import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useTripsList() {
    const tripListTable = ref([]);

    const tripsTableColumns = [
        { text: i18n.t('Trip'), value: 'short_name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Route'), value: 'route.long_name', class: 'text-uppercase', width: '10rem' },
        { text: i18n.t('BikesAllowed'), value: 'bikes_allowed', class: 'text-uppercase', width: '12rem' },
        {
            text: i18n.t('WheelchairAcessible'),
            value: 'wheelchair_accessible',
            class: 'text-uppercase',
            width: '12rem',
        },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalTripListTable = tripListTable.length;
    const oldSearch = ref('');
    const oldBikeFilter = ref('');
    const oldWheelchairFilter = ref('');

    const fetchTripList = () => {
        let verifyQuery = store.getters['app-routes/getTripsQuery'];
        store
            .dispatch('app-routes/fetchTripList', {
                //  ...(searchQuery.value && { q: searchQuery.value }),
                // ...(countryFilter.value && { country: countryFilter.value }),
            })
            .then((response) => {
                console.log(response);
                // const { filteredData, total, parkTotal } = response.data;

                if (
                    oldSearch.value == verifyQuery.filters.$or[0].short_name.$containsi &&
                    oldBikeFilter.value == verifyQuery.filters.bikes_allowed.$containsi &&
                    oldWheelchairFilter.value == verifyQuery.filters.wheelchair_accessible.$containsi
                ) {
                    tripListTable.value.push(...response.data.data);
                    tripListTable.value = _.uniqBy(tripListTable.value, 'id');
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].short_name.$containsi;
                    oldBikeFilter.value = verifyQuery.filters.bikes_allowed.$containsi;
                    oldWheelchairFilter.value = verifyQuery.filters.wheelchair_accessible.$containsi;
                    tripListTable.value = [];
                    tripListTable.value.push(...response.data.data);
                    tripListTable.value = _.uniqBy(tripListTable.value, 'id');
                }

                totalTripListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return {
        tripsTableColumns,
        tripListTable,
        totalTripListTable,
        fetchTripList,
    };
}
